export default {
    updateMapChart(chart, productStat) {
        const option = {
            tooltip: {
                trigger: "item",
                formatter: (params) => {
                    return `${params.name}<br/>${params.data ? params.data.value : 0}个`;
                }
            },
            visualMap: {
                show: false,
                min: 0,
                max: 30,
                inRange: {
                    color: ["#ffdf80", "#ff8c00"]
                }
            },
            series: [
                {
                    type: "map",
                    map: "中华人民共和国",
                    layoutCenter: ["52%", "59%"],
                    layoutSize: 1000,
                    selectedMode: false,
                    itemStyle: {
                        areaColor: "#707473",
                        borderColor: "#000000",
                        borderWidth: 1.5
                    },
                    emphasis: {
                        label: {
                            show: false
                        }
                    },
                    data: productStat.value.provinceDistributed.map((item) => {
                        return {
                            name: item.province,
                            value: item.count
                        };
                    })
                }
            ]
        };
        chart.setOption(option, true);
    },
    updateCategoryChart(chart, productCategory, store) {
        const option = {
            tooltip: {
                trigger: "item"
            },
            legend: {
                icon: "rect",
                itemWidth: 10,
                itemHeight: 10,
                orient: "vertical",
                right: 10,
                top: 90,
                textStyle: {
                    color: store.getters["theme/secondaryFontColor"]
                }
            },
            series: [
                {
                    type: "pie",
                    radius: "50%",
                    center: ["40%", "50%"],
                    label: {
                        color: store.getters["theme/mainFontColor"]
                    },
                    labelLine: {
                        lineStyle: {
                            color: store.getters["theme/mainFontColor"]
                        }
                    },
                    data: [
                        {
                            name: "优先股",
                            value: productCategory.value.priorityStock,
                            itemStyle: {
                                color: "#F74B0C"
                            }
                        },
                        {
                            name: "同股同权",
                            value: productCategory.value.sameSharesSameRights,
                            itemStyle: {
                                color: "#28F3C2"
                            }
                        },
                        {
                            name: "投后管理",
                            value: productCategory.value.investManager,
                            itemStyle: {
                                color: "#ACE1FF"
                            }
                        }
                    ]
                }
            ]
        };
        chart.setOption(option, true);
    },
    updateRateChart(chart, productStat, store) {
        const colors = ["#F74B0C", "#28F3C2", "#ACE1FF", "#FFBE00", "#F01D1D", "#12B1FF"];
        const option = {
            tooltip: {
                trigger: "item"
            },
            series: [
                {
                    type: "pie",
                    radius: ["40%", "60%"],
                    label: {
                        color: store.getters["theme/mainFontColor"]
                    },
                    labelLine: {
                        lineStyle: {
                            color: store.getters["theme/mainFontColor"]
                        }
                    },
                    data: productStat.value.provinceDistributed.map((item, index) => {
                        return {
                            name: item.province,
                            value: item.count,
                            itemStyle: {
                                color: colors[index]
                            }
                        };
                    })
                }
            ]
        };
        chart.setOption(option, true);
    },
    updateCountChart (chart, productStat, store) {
        const option = {
            tooltip: {
                trigger: "item"
            },
            legend: {
                icon: "rect",
                itemWidth: 10,
                itemHeight: 10,
                textStyle: {
                    color: store.getters["theme/secondaryFontColor"]
                }
            },
            grid: {
                bottom: "10%",
                left: "10%",
                containLabel: true
            },
            xAxis: {
                type: "category",
                axisLabel: {
                    interval: 0,
                    color: store.getters["theme/mainFontColor"]
                },
                data: productStat.value.provinceDistributed.map((item) => item.province)
            },
            yAxis: {
                type: "value",
                axisLabel: {
                    color: store.getters["theme/mainFontColor"]
                },
                splitLine: {
                    lineStyle: {
                        type: "dashed",
                        color: store.getters["theme/secondaryBorderColor"]
                    }
                },
                interval: 1
            },
            series: [
                {
                    type: "bar",
                    itemStyle: {
                        color: store.getters["theme/brandColor"]
                    },
                    label: {
                        show: true,
                        color: "#FFFFFF"
                    },
                    data: productStat.value.provinceDistributed.map((item) => item.count)

                }
            ]
        };
        chart.setOption(option, true);
    }
}
