import axios from "axios"
import qs from "qs";
import { ElNotification } from "element-plus";
import router from "@/router";
import { getToken } from "@/utils/auth";
import Config from "@/settings";

const service = axios.create({
  baseURL: process.env.NODE_ENV === "production" ? process.env.VUE_APP_BASE_API : "/",
  timeout: Config.timeout
});

service.interceptors.request.use(
  (config) => {
    if (config.method === "post" && !config.headers["Content-Type"]) {
      config.headers["Content-Type"] = "application/x-www-form-urlencoded";
      config.data = qs.stringify(config.data);
    }
    const token = getToken();
    if (token) {
      config.headers["Authorization"] = token;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

service.interceptors.response.use(
  (response) => {
    if (response.data.ret === 10002) {
      router.replace("/");
    }
    if (response.data.ret !== 0 && response.data.ret !== 10002 && !response.request.responseURL.endsWith("/auth/check")) {
      console.warn(response.data.msg, response.config.url);
      // ElNotification.error({ title: response.data.msg });
    }
    return response.data;
  },
  error => {
    if (error.message) {
      ElNotification.error({ title: error.message });
    }
  }
);

export default service;
