<template>
  <div class="inner" :theme="$store.state.theme.name">
    <router-view />
  </div>
</template>

<script>
import { onBeforeUnmount } from "vue";
import screen from "@/compositions/screen";

export default {
  name: "App",
  setup() {    
    screen.init();
    onBeforeUnmount(() => {
      screen.destroy();
    });
  }
}
</script>

<style lang="scss">
@import "@/styles/theme.scss";

html {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  width: 100%;
  height: 100%;
}

#app {
  display: flex;
  width: 100%;
  height: 100%;
}

.inner {
  display: flex;
  width: 100%;
  height: 100%;
}
</style>
