import { reactive, computed, watch, watchEffect, toRefs } from "vue";
import { useRouter } from "vue-router";
import user from "@/compositions/user";
import productApi from "@/apis/productApi";
import projectApi from "@/apis/projectApi";

let composition = null;

function useProduct() {
    const router = useRouter();

    const { ready, userInfo, permissions } = user.init();

    const state = reactive({
        productTree: [],
        productList: [],
        selectProductId: null,
        productId: null,
        productIntroduction: {},
        productOverview: {},
        productNetWorthData: {},
        projectStat: {},
        announcementList: [],
        projectList: [],
        projectId: null
    });

    const hasPermission = computed(() => {
        const perm = `prod:view:${state.productId}`;
        return permissions.value.includes(perm);
      });

    const project = computed(() => {
        for (let i = 0; i < state.projectList.length; i++) {
            if (state.projectList[i].projectId === state.projectId) {
                return state.projectList[i];
            }
        }
        return null;
    });

    const getProductTree = async () => {
        try {
            const response = await projectApi.getProductTree();
            if (response.ret === 0) {
                response.data.forEach(product => {
                    product.id = `product-${product.id}`;
                    product.children.forEach(project => {
                        project.id = `project-${project.id}`;
                    });
                });
                state.productTree = response.data;
                state.productTree.unshift({ id: 0, name: "综合看板" });
            }
        } catch (error) {
            console.warn(error);
        }
    };

    const getProductList = async () => {
        try {
            const response = await productApi.getProductList();
            if (response.ret === 0) {
                state.productList = response.data;
            }
        } catch (error) {
            console.warn(error);
        }
    };

    const getProductIntroduction = async () => {
        try {
            const response = await productApi.getProductIntroduction(state.productId);
            if (response.ret === 0) {
                state.productIntroduction = response.data;
            }
        } catch (error) {
            console.warn(error);
        }
    }

    const getProductNetWorthData = async () => {
        try {
            const response = await productApi.getProductNetWorthData(state.productId);
            if (response.ret === 0) {
                state.productNetWorthData = response.data;
            }
        } catch (error) {
            console.warn(error);
        }
    }

    const getProjectStat = async () => {
        try {
            const response = await productApi.getProjectStat(state.productId);
            if (response.ret === 0) {
                state.projectStat = response.data;
            }
        } catch (error) {
            console.warn(error);
        }
    }

    const getAnnouncementList = async () => {
        try {
            const response = await productApi.getAnnouncementList(state.productId);
            if (response.ret === 0) {
                state.announcementList  = response.data;
            }
        } catch (error) {
            console.warn(error);
        }
    }  

    const getProjectList = async () => {
        try {
            const response = await productApi.getProjectList(state.productId);
            if (response.ret === 0) {
                state.projectList = response.data;
                if (!state.projectId && state.projectList.length > 0) {
                    state.projectId = state.projectList[0].projectId;
                }
            }
        } catch (error) {
            console.warn(error);
        }
    }

    watchEffect(() => {
        if (ready.value) {
            if (Object.keys(userInfo.value).length === 0) {
                router.replace("/");
            } else if (hasPermission.value) {
                getProductTree();
                if (state.productId) {
                    getProductList();
                    getProductIntroduction();
                    getProductNetWorthData();
                    getProjectStat();
                    getAnnouncementList();
                    getProjectList();
                }
            }
        }
    });

    watch(() => state.productId, () => {
        state.productIntroduction = {};
        state.productOverview = {};
        state.productNetWorthData = {};
        state.projectStat = {};
        state.announcementList = [];
        state.projectList = [];
        state.projectId = null;
    });

    return {
        ...toRefs(state),
        project,
        hasPermission
    };
}

export default {
    init() {
        if (!composition) {
            composition = useProduct();
        }
        return composition;
    },
    destroy() {
        composition = null;
    }
}