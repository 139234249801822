<template>
  <div class="general-left">
    <div class="section top">
      <title-bar :icon="infoIcon" title="公司简介" />
      <div class="separator" />
      <div class="body" v-if="Object.keys(axIntro).length > 0">
        <div class="company">
          <div class="avatar">
            <span class="text">安</span>
          </div>
          <div class="info">
            <span class="name">{{axIntro.companyName}}</span>
            <span class="position">{{axIntro.companyAddress}}</span>
          </div>
        </div>
        <span class="company-text">私募基金管理人编号：{{axIntro.fundNo}}</span>
        <span class="company-text">中国保险资产管理业协会联席会员代码：{{axIntro.associationCode}}</span>
        <div class="company-separator" />
        <div class="datas">
          <div class="item">
            <span class="title">安星待回收跟投款</span>
            <div class="value">
              <span class="num">{{axIntro.toBeRecycledFunds}}</span>
              <span class="unit">万</span>
            </div>
          </div>
          <div class="item">
            <span class="title">累计产品个数</span>
            <div class="value">
              <span class="num">{{axIntro.totalProductCount}}</span>
              <span class="unit">个</span>
            </div>
          </div>
          <div class="item">
            <span class="title">存续产品个数</span>
            <div class="value">
              <span class="num">{{axIntro.subsistingProductCount}}</span>
              <span class="unit">个</span>
            </div>
          </div>
          <div class="item">
            <span class="title">安星累计实现收益</span>
            <div class="value">
              <span class="num">{{axIntro.totalImplIncome}}</span>
              <span class="unit">万</span>
            </div>
          </div>
          <div class="item">
            <span class="title">累计合同规模</span>
            <div class="value">
              <span class="num">{{axIntro.totalContractScale}}</span>
              <span class="unit">亿</span>
            </div>
          </div>
          <div class="item">
            <span class="title">存续管理规模</span>
            <div class="value">
              <span class="num">{{axIntro.subsistingManagerScale}}</span>
              <span class="unit">亿</span>
            </div>
          </div>
        </div>
      </div>
      <div class="body" v-else>
        <empty />
      </div>
    </div>
    <div class="section middle">
      <title-bar :icon="pieIcon" title="产品分类" />
      <div class="separator" />
      <div class="body" id="category-chart" v-if="Object.keys(productCategory).length > 0" />
      <div class="body" v-else>
        <empty />
      </div>
    </div>
    <div class="section bottom">
      <title-bar :icon="productInfoIcon" title="产品信息">
        <template v-slot:right>
          <el-select class="selector" size="mini" placeholder="选择产品" v-model="productId" @change="onProductSelect">
            <el-option :label="product.productName" :value="product.productId" v-for="product in productList" :key="product.productId" @click="onProductClick(product)" />
          </el-select>
        </template>
      </title-bar>
      <div class="separator" />
      <div class="body" v-if="productList.length > 0">
        <swiper class="swiper" ref="swiper" :autoplay="{delay: 5000}" @slideChange="onProductChange">
          <swiper-slide v-for="product in productList" :key="product.productId">
            <div class="product">
              <div class="field">
                <span class="title">产品名称</span>
                <span class="text">{{product.productName}}</span>
              </div>
              <div class="field">
                <span class="title">产品备案号</span>
                <span class="text">{{product.recordNo}}</span>
              </div>
              <div class="field">
                <span class="title">托管机构</span>
                <span class="text">{{product.custodian}}</span>
              </div>
              <div class="field">
                <span class="title">成立日期</span>
                <span class="text">{{product.foundDate}}</span>
              </div>
              <div class="field">
                <span class="title">被投资企业名称</span>
                <span class="text">{{product.fundInvestedCompany}}</span>
              </div>
              <button class="btn-detail" @click="onProductClick(product)">
                <span class="text">查看详情</span>
                <img class="icon" :src="playIcon" />
              </button>
            </div>
          </swiper-slide>
        </swiper>
        <div class="indicator">
          <div :class="['dot', product.productId === productId ? 'current' : '']" v-for="product in productList" :key="product.productId" />
        </div>
      </div>
      <div class="body" v-else>
        <empty />
      </div>
    </div>
	</div>
</template>

<script>
import { ref, computed, watchEffect, onMounted, onBeforeUnmount, nextTick } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import * as echarts from "echarts";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import TitleBar from "@/components/common/TitleBar";
import Empty from "@/components/common/Empty";
import general from "@/compositions/general";
import generalCharts from "@/charts/generalCharts";

export default {
  name: "GeneralLeft",
  components: {
    Swiper,
    SwiperSlide,
    TitleBar,
    Empty
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const themeName = computed(() => store.state.theme.name);
    const infoIcon = computed(() => store.getters["theme/infoIcon"]);
    const pieIcon = computed(() => store.getters["theme/pieIcon"]);
    const productInfoIcon = computed(() => store.getters["theme/productInfoIcon"]);
    const playIcon = computed(() => store.getters["theme/playIcon"]);
    const swiper = ref(null);

    const data = general.init();
    const { axIntro, productCategory, productList, productId, productIndex } = data;

    let categoryChart = null;

    onMounted(() => {
      watchEffect(() => {
        if (themeName.value && Object.keys(productCategory.value).length > 0) {
          nextTick(() => {
            if (!categoryChart) {
              categoryChart = echarts.init(document.getElementById("category-chart"));
            }
            generalCharts.updateCategoryChart(categoryChart, productCategory, store);
          });
        } else {
          if (categoryChart) {
            categoryChart.dispose();
          }
          categoryChart = null;
        }
      });
    });

    const onProductSelect = () => {
      swiper.value.$el.swiper.slideTo(productIndex.value);
    };

    const onProductChange = (event) => {
      const index = event.realIndex;
      productId.value = productList.value[index].productId;
    };

    const onProductClick = (product) => {
      router.push({
        path: "/product", 
        query: { 
          id: product.productId 
        }
      });
    };

    onBeforeUnmount(() => {
      if (categoryChart) {
        categoryChart.dispose();
      }
      categoryChart = null;
    });

    return {
      infoIcon,
      pieIcon,
      productInfoIcon,
      playIcon,
      axIntro,
      productList,
      productId,
      productIndex,
      swiper,
      productCategory,
      onProductSelect,
      onProductChange,
      onProductClick
    };
  }
}
</script>

<style lang="scss">
.popper {
  background-color: #333333 !important;
  border: none !important;

  .el-cascader-node__label {
    color: #999999;
  }

  .el-radio__inner {
    background-color: #333333 !important;
    border: 1px solid #999999;
  }

  .el-radio__input.is-checked .el-radio__inner {
    background-color: #FF8517 !important;
    border: none;
  }

  .el-cascader-menu {
    border: 1px solid rgba(255, 255, 255, 0.1);
  }

  .el-cascader-node.is-active {
    color: #FF8517;
  }

  .el-popper__arrow::before {
    background-color: #333333 !important;
    border-color: #333333 !important;
  }

  .el-select-dropdown__item {
    color: #999999;
  }

  .el-select-dropdown__item.selected {
    color: #FF8517
  }
}
</style>

<style lang="scss" scoped>
@import "@/styles/main.scss";

.general-left {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.section {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  margin-left: 16px;
  width: 376px;
  background-color: var(--main-background-color);

  &.top {
    height: 390px;
  }

  &.middle {
    height: 290px;
  }

  &.bottom {
    flex: 1;
  }

  .selector {
    width: 176px;
  }

  .body {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;

    .company {
      @extend %horizontal;
      align-self: center;
      margin-top: 12px;
      margin-bottom: 6px;
      width: 344px;
      height: 48px;

      .avatar {
        @extend %center;
        width: 48px;
        height: 48px;
        background-color: var(--secondary-background-color);
        border: 1px solid var(--main-border-color);

        .text {
          font-size: 16px;
          font-family: MicrosoftYaHeiSemibold;
          color: var(--main-font-color);
        }
      }

      .info {
        display: flex;
        flex-direction: column;
        margin-left: 12px;

        .name {
          font-size: 16px;
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
          font-weight: bold;
          color: var(--main-font-color);
          line-height: 21px;
        }

        .position {
          margin-top: 4px;
          font-size: 14px;
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
          font-weight: bold;
          color: var(--tip-font-color);
          line-height: 19px;
        }
      }
    }

    .company-text {
      margin-top: 12px;
      margin-left: 16px;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: var(--main-font-color);
      line-height: 20px;
    }

    .company-separator {
      align-self: center;
      margin-top: 22px;
      width: 344px;
      height: 1px;
      background-color: var(--secondary-border-color);
    }

    .datas {
      display: flex;
      flex-wrap: wrap;

      .item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 125px;
        height: 89.5px;

        .title {
          margin-left: 16px;
          font-size: 12px;
          font-family: MicrosoftYaHei;
          color: var(--main-font-color);
          line-height: 20px;
        }

        .value {
          display: flex;
          margin-left: 16px;

          .num {
            font-size: 28px;
            font-family: JetBrainsMonoRoman-Medium, JetBrainsMonoRoman;
            font-weight: 500;
            color: var(--brand-color);
            line-height: 37px;
          }

          .unit {
            margin-top: 14px;
            margin-left: 4px;
            font-size: 14px;
            font-family: MicrosoftYaHei;
            color: var(--tip-font-color);
            line-height: 19px;
          }
        }
      }
    }

    .swiper {
      width: 100%;
      height: 240px;
    }

    .product {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
    }

    .field {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;

      &:first-child {
        margin-top: 12px;
      }

      .title {
        margin-left: 16px;
        width: 70px;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: var(--secondary-font-color);
        line-height: 20px;
      }

      .text {
        margin-left: 14px;
        margin-right: 8px;
        width: 268px;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: var(--main-font-color);
        line-height: 20px;
        @include ellipsis(3);
      }
    }

    .btn-detail {
      position: absolute;
      bottom: 2px;
      right: 12px;
      @extend %center;
      align-self: flex-end;
      width: 84px;
      height: 24px;
      background-color: var(--secondary-background-color);

      .text {
        font-size: 12px;
        font-family: MicrosoftYaHeiSemibold;
        color: var(--main-font-color);
      }

      .icon {
        margin-left: 4px;
        width: 16px;
        height: 16px;
      }
    }

    .indicator {
      position: absolute;
      bottom: 11px;
      left: 12px;
      @extend %horizontal;

      .dot {
        margin-right: 6px;
        width: 6px;
        height: 6px;
        background-color: var(--main-border-color);

        &.current {
          background-color: var(--main-font-color);
        }
      }
    }
  }
}
</style>
