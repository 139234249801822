export default {
    checkMobile(str) {
        const reg = /^1[0-9]{10}$/;
        if (reg.test(str)) {
            return true;
        }
        return false;
    },
    thousandsSeparatorNumber(num) {
		let result = "";
		let str = num.toString();
		const index = str.indexOf(".");
		if (index > 0) {
			result = str.substr(index);
			str = str.substr(0, index);
		}
		while (str.length > 3) {
			result = "," + str.substr(str.length - 3) + result;
			str = str.substr(0, str.length - 3);
		}
		result = str + result;
		return result;
	}
}