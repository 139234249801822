<template>
  <div class="clock">
    <div class="date">
      <img class="icon" :src="dateIcon" />
      <span class="date-text">{{date}}</span>
      <span class="day-text">{{day}}</span>
    </div>
    <div class="time">
      <img class="icon" :src="timeIcon" />
      <span class="text">{{time}}</span>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, computed, onBeforeUnmount } from "vue";
import { useStore } from "vuex";
import moment from "moment";

export default {
  name: "Clock",
  setup() {
    const store = useStore();
    const dateIcon = computed(() => store.getters["theme/dateIcon"]);
    const timeIcon = computed(() => store.getters["theme/timeIcon"]);
    const state = reactive({
      date: "",
      day: "",
      time: ""
    });
    let timer = null;

    onBeforeUnmount(() => {
      clearTimer();
    });

    const startTimer = () => {
      clearTimer();
      updateTimer();
      timer = setInterval(updateTimer, 1000);
    };

    const updateTimer = () => {
      const mmt = moment();
      state.date = mmt.format("YYYY-MM-DD");
      state.day = mmt.format("dddd");
      state.time = mmt.format("HH:mm:ss");
    };

    const clearTimer = () => {
      if (timer) {
        clearInterval(timer);
        timer = null;
      }
    };

    startTimer();

    return {
      dateIcon,
      timeIcon,
      ...toRefs(state)
    };
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/main.scss";

.clock {
  @extend %horizontal;

  .date {
    @extend %center;
    width: 232px;
    height: 40px;

    .icon {
      width: 24px;
      height: 24px;
    }

    .date-text {
      margin-left: 8px;
      font-size: 16px;
      font-family: JetBrainsMono-Regular, JetBrainsMono;
      color: var(--main-font-color);
    }

    .day-text {
      margin-left: 8px;
      font-size: 16px;
      font-family: MicrosoftYaHei;
      color: var(--main-font-color);
    }
  }

  .time {
    @extend %center;
    width: 196px;
    height: 40px;

    .icon {
      width: 24px;
      height: 24px;
    }

    .text {
      margin-left: 8px;
      font-size: 24px;
      font-family: JetBrainsMonoRoman-Bold, JetBrainsMonoRoman;
      font-weight: bold;
      color: var(--main-font-color);
    }
  }
}
</style>

