<template>
  <el-dropdown class="dropdown" @command="onCommand">
    <button>
      <img class="icon" :src="changeThemeIcon" />
    </button>
    <template v-slot:dropdown>
      <el-dropdown-menu>
        <el-dropdown-item command="password">修改密码</el-dropdown-item>
        <el-dropdown-item command="theme" disabled>切换主题</el-dropdown-item>
        <el-dropdown-item command="logout" divided>退出登录</el-dropdown-item>
      </el-dropdown-menu>
    </template>


    <el-dialog v-model="dialogFormVisible" title="修改密码" width="400px">
      <el-form :model="form" ref="passwordForm" :rules="rules" label-width="80px">
        <el-form-item label="密码" prop="password">
          <el-input v-model="form.password" type="password" autocomplete="off" />
        </el-form-item>
        <el-form-item label="确认密码" prop="confirmedPassword">
          <el-input v-model="form.confirmedPassword" type="password" autocomplete="off" />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取消</el-button>
          <el-button type="primary" @click="submitForm(passwordForm)">确认</el-button
          >
        </span>
      </template>
    </el-dialog>

  </el-dropdown>
</template>

<script>
import { computed, ref, reactive } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { ElMessage  } from "element-plus";
import authApi from "@/apis/authApi";

export default {
  name: "Commands",
  props: ["desc"],
  setup() {
    const store = useStore();
    const router = useRouter();
    const changeThemeIcon = computed(() => store.getters["theme/changeThemeIcon"]);
    const dialogFormVisible = ref(false);
    const passwordForm = ref()

    const form = reactive({
      password: '',
      confirmPassword: ''
    })

    const validateSamePassword = (rule, value, callback) => {
      if (value !== form.password) {
        callback(new Error("确认密码与密码不相同"))
      }

      callback()
    }


    const rules = reactive({
      password: [
        { required: true, message: '请输入密码', trigger: 'blur' },
        { min: 6, max: 16, message: '密码最少6位', trigger: 'blur' }
      ],
      confirmedPassword: [
        { required: true, message: '请输入确认密码', trigger: 'blur' },
        { validator: validateSamePassword, trigger: 'blur' }
      ],
    })

    const submitForm = async (formEl) => {
      if (!formEl) return
      await formEl.validate((valid, fields) => {
        if (!valid) {
          console.log('error submit!', fields)
          return
        }

        requestChangePassword(form.password)
      })
    }

    const requestChangePassword = async (password) => {
        const response = await authApi.changePassword(form.password);
        if (response.ret !== 0) {
          ElMessage.error(response.msg);
          return;
        }

        ElMessage.success('修改密码成功');
        dialogFormVisible.value = false
    }

    const changePassword = () => {
      form.password = ''
      form.confirmedPassword = ''
      dialogFormVisible.value = true;
    };

    const changeTheme = () => {
      store.commit("theme/changeTheme");
    };

    const logout = async () => {
      await authApi.logout();
      router.replace("/");
    };

    const onCommand = (command) => {
      switch (command) {
        case "theme":
          changeTheme();
          break;
        case "logout":
          logout();
          break;
        case "password":
          changePassword();
          break;
      }
    };

    return {
      dialogFormVisible,
      changeThemeIcon,
      onCommand,
      form,
      submitForm,
      passwordForm,
      rules
    };
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/main.scss";

.dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 16px;
  @extend %center;
  margin: auto;
  width: 32px;
  height: 32px;
  background-color: var(--secondary-border-color);
  border-radius: 16px 0 0 16px;

  .icon {
    width: 18px;
    height: 18px;
  }

  :deep(.el-input__inner) {
    border: var(--el-input-border,var(--el-border-base));
    color: var(--el-input-text-color,var(--el-text-color-regular));
    cursor: text;
  }

  :deep(.el-button) {
    padding: 12px 20px;
    border: var(--el-border-base);
    background-color: var(--el-button-bg-color);
  }

}

</style>
