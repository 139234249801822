import { createStore } from "vuex";
import theme from "./theme";
import user from "./user";

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    theme,
    user
  }
});
