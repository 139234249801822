<template>
  <div class="general-map">
    <div class="chart" id="map-chart" />
    <template v-if="clickEvent">
      <div class="mask" @click="onMaskClick" />
      <div class="selector" :style="selectorStyle">
        <template v-for="(product, index) in clickEvent.products" :key="product.id">
          <div class="product">
            <span class="text">{{product.name}}</span>
          </div>
          <button class="project" v-for="project in product.projects" :key="project.id" @click="onProjectClick(project.id)">
            <span class="text">{{project.name}}</span>
          </button>
          <div class="product-separator" v-if="index < clickEvent.products.length - 1" />
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import { ref, computed, watchEffect, onMounted, onBeforeUnmount } from "vue";
import { useRouter } from "vue-router";
import * as echarts from "echarts";
import general from "@/compositions/general";
import generalCharts from "@/charts/generalCharts";
import geo from "@/assets/geos/中华人民共和国";

export default {
  name: "GeneralMap",
  setup() {
    const router = useRouter();
    const data = general.init();
    const { productStat } = data;
    const clickEvent = ref(null);
    const selectorStyle = computed(() => {
      const style = {};
      if (clickEvent.value) {
        if (clickEvent.value.y > 500) {
          style.bottom = `${1080 - clickEvent.value.y}px`;
        } else {
          style.top = `${clickEvent.value.y}px`;
        }
        style.left = `${clickEvent.value.x}px`;
      }
      return style;
    });

    echarts.registerMap("中华人民共和国", geo);

    let mapChart = null;

    onMounted(() => {
      watchEffect(() => {
        if (Object.keys(productStat.value).length > 0) {
          if (!mapChart) {
            mapChart = echarts.init(document.getElementById("map-chart"));
          }
          generalCharts.updateMapChart(mapChart, productStat);
          mapChart.on("click", (params) => {
              if (params.data && productStat.value.provinceDistributed) {
                let products = [];
                for (const province of productStat.value.provinceDistributed) {
                  if (province.province === params.data.name) {
                    products = province.products;
                    break;
                  }
                }
                clickEvent.value = { province: params.data.name, x: params.event.offsetX, y: params.event.offsetY, products: products };
              }
          });
        } else {
          if (mapChart) {
            mapChart.dispose();
            mapChart = null;
          }
        }
      });
    });

    const onMaskClick = () => {
      clickEvent.value = null;
    };

    const onProjectClick = (id) => {
      router.push({
        path: "/project", 
        query: { 
          id: id 
        }
      });
    };

    onBeforeUnmount(() => {
      if (mapChart) {
        mapChart.dispose();
        mapChart = null;
      }
    });

    return {
      clickEvent,
      selectorStyle,
      onMaskClick,
      onProjectClick
    };
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/main.scss";

.general-map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .chart {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .selector {
    position: absolute;
    @extend %vertical;
    padding: 6px 0;
    min-width: 124px;
    max-width: 240px;
    background-color: #333333;
    border-radius: 4px;
    box-sizing: border-box;
    border: 1px solid var(--secondary-border-color);

    .product {
      @extend %horizontal;
      width: 100%;
      height: 30px;

      .text {
        margin: 0 20px;
        font-family: MicrosoftYaHei;
        font-size: 12px;
        color: var(--tip-font-color);
        @include ellipsis();
      }
    }

    .project {
      @extend %horizontal;
      width: 100%;
      height: 34px;

      .text {
        margin: 0 20px;
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: var(--secondary-font-color);
        @include ellipsis();
      }

      &:hover {
        background-color: var(--secondary-background-color);
      }
    }

    .product-separator {
      margin: 12px 0;
      width: calc(100% - 24px);
      height: 1px;
      background-color: var(--secondary-border-color);
    }
  }
}
</style>
