import request from "@/utils/request";

function checkSession() {
  return request({
    url: "auth/check",
    method: "get"
  });
}

function getVCode() {
  return request({
    url: "auth/code",
    method: "get"
  });
}

function passwordLogin(username, password, uuid, code) {
  return request({
    url: "auth/login",
    method: "post",
    data: { username: username, password: password, uuid: uuid, code: code }
  });
}

function sendVCode(mobile) {
  return request({
    url: "auth/send_code",
    method: "get",
    params: { mobile: mobile }
  });
}

function mobileLogin(mobile, code) {
  return request({
    url: "auth/sms-login",
    method: "post",
    data: { mobile: mobile, code: code }
  });
}

function logout() {
  return request({
    url: "auth/logout",
    method: "post"
  });
}

function changePassword(password) {
  return request({
    url: "auth/change_password",
    method: "post",
    data: {password: password}
  })
}

export default {
  checkSession,
  getVCode,
  passwordLogin,
  sendVCode,
  mobileLogin,
  logout,
  changePassword
}