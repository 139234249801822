<template>
  <div class="general">
    <div class="inner" :style="style">
      <general-map />
      <div class="header">
        <general-header />
      </div>
      <div class="left">
        <general-left />
      </div>
      <general-middle />
      <div class="right">
        <general-right />
      </div>
      <transition name="scale">
        <no-permission v-if="ready && !hasPermission" />
      </transition>
    </div>
  </div>
</template>

<script>
import { onBeforeUnmount } from "vue";
import user from "@/compositions/user";
import screen from "@/compositions/screen";
import general from "@/compositions/general";
import GeneralMap from "@/components/general/GeneralMap";
import GeneralHeader from "@/components/general/GeneralHeader";
import GeneralLeft from "@/components/general/GeneralLeft";
import GeneralMiddle from "@/components/general/GeneralMiddle";
import GeneralRight from "@/components/general/GeneralRight";
import NoPermission from "@/components/common/NoPermission";

export default {
  name: "General",
  components: {
    GeneralMap,
    GeneralHeader,
    GeneralLeft,
    GeneralMiddle,
    GeneralRight,
    NoPermission
  },
  setup() {
    const { ready } = user.init();
    const { style } = screen.init();
    const { hasPermission } = general.init();

    onBeforeUnmount(() => {
      general.destroy();
    });

    return {
      style,
      ready,
      hasPermission
    };
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/main.scss";

.general {
  @extend %center;
  width: 100%;
  height: 100%;
  background: var(--general-bg) no-repeat center center;
  background-size: cover;
}

.inner {
  position: relative;
  @extend %vertical;
  flex-shrink: 0;
  width: 1920px;
  height: 1080px;
  transform-origin: center center;
  overflow: hidden;
}

.header {
  display: flex;
  width: 100%;
  z-index: 2;
}

.left {
  position: absolute;
  top: 80px;
  left: 0;
  display: flex;
  width: 400px;
  height: 1000px;
  z-index: 2;
}

.right {
  position: absolute;
  top: 80px;
  right: 0;
  display: flex;
  width: 400px;
  height: 1000px;
  z-index: 2;
}
</style>
