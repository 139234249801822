<template>
  <div class="login" v-loading="isLoading">
    <div class="inner" :style="style">
      <Logo class="logo" title="MAIMS" />
      <div class="panel">
        <div class="line" />
        <div class="title">
          <span class="text">登录</span>
        </div>
        <div class="tabs" v-if="!qrcodeLogin">
          <button 
            :class="['tab', tab.id === currentTab ? 'current' : '']" 
            v-for="tab in tabs" 
            :key="tab.id" 
            @click="onTabClick(tab)"
          >
            {{tab.name}}
          </button>
          <div :class="['tab-line', currentTab === 2 ? 'right' : '']" />
        </div>
        <template v-if="qrcodeLogin">
          <div class="qrcode">
            <div class="image" />
          </div>
        </template>
        <template v-else-if="currentTab === 1">
          <span class="field-title">账号</span>
          <input class="input" maxlength="30" v-model="account" />
          <span class="field-title">密码</span>
          <input class="input" type="password" maxlength="30" v-model="password" />
          <span class="field-title">验证码</span>
          <div class="field">
            <input class="input vcode-input" type="vcode" maxlength="4" v-model="vcode" />
            <button class="btn-vcode" :disabled="isRefeshVCodeLoading" v-loading="isRefeshVCodeLoading" @click="onVCodeClick">
              <img class="image" :src="vcodeImage" />
            </button>
          </div>
          <button class="btn-login" :disabled="isLoginLoading" v-loading="isLoginLoading" @click="onLoginClick">登录</button>
        </template>
        <template v-else>
          <span class="field-title">手机号</span>
          <input class="input" maxlength="11" v-model="mobile" />
          <span class="field-title">验证码</span>
          <div class="field">
            <input class="input vcode-input" type="vcode" maxlength="6" v-model="vcode" />
            <button class="btn-send-vcode disable" v-if="sendVCodeCD > 0">{{sendVCodeCD}}</button>
            <button 
              class="btn-send-vcode" 
              :disabled="isSendVCodeLoading" 
              v-loading="isSendVCodeLoading" 
              @click="onSendVCodeClick" 
              v-else
            >
              获取验证码
            </button>
          </div>
          <button class="btn-login" :disabled="isLoginLoading" v-loading="isLoginLoading" @click="onLoginClick">登录</button>
        </template>
        <button class="btn-login-type" @click="onChangeLoginTypeClick">
          <span class="text">使用二维码登录</span>
        </button>
        <button class="corner" @click="onChangeLoginTypeClick">
          <img class="icon" :src="qrcodeLogin ? '/images/pc.png' : '/images/qrcode.png'" />
        </button>
      </div>
      <span class="rights">@版权声明</span>
    </div>
  </div>
</template>

<script>
import { readonly, reactive, toRefs, watchEffect, onBeforeUnmount } from "vue";
import { useRouter } from "vue-router";
import { ElMessage  } from "element-plus";
import commonUtil from "@/utils/common";
import authApi from "@/apis/authApi";
import user from "@/compositions/user";
import screen from "@/compositions/screen";
import general from "@/compositions/general";
import product from "@/compositions/product";
import project from "@/compositions/project";
import Logo from "@/components/common/Logo";

export default {
  name: "Login",
  components: {
    Logo
  },
  setup() {
    general.destroy();
    product.destroy();
    project.destroy();

    const router = useRouter();
    user.destroy();
    const { ready, isLoading, userInfo, permissions } = user.init();
    const { style } = screen.init();
    const tabs = readonly([
      { id: 1, name: "账号登录" },
      { id: 2, name: "手机验证登录" }
    ]);
    const state = reactive({
      currentTab: 1,
      vcodeImage: "",
      uuid: "",
      account: "",
      password: "",
      vcode: "",
      mobile: "",
      qrcodeLogin: false,
      sendVCodeCD: 0,
      vcodeRefreshTime: 0,
      isRefeshVCodeLoading: false,
      isLoginLoading: false,
      isSendVCodeLoading: false
    });
    let sendVCodeTimer = null;

    onBeforeUnmount(() => {
      clearSendVCodeTimer()
    });

    const onTabClick = (tab) => {
      state.currentTab = tab.id;
      state.vcode = "";
    };

    const onVCodeClick = () => {
      const now = Date.now();
      if (now - state.vcodeRefreshTime < 5000) {
        ElMessage({
          type: "warning",
          message: "点击过快"
        });
        return;
      }
      state.vcodeRefreshTime = now;
      getVCode();
    };

    const onSendVCodeClick = () => {
      if (state.mobile.trim() === "") {
        ElMessage({
          type: "warning",
          message: "请输入手机号"
        });
        return;
      }
      if (!commonUtil.checkMobile(state.mobile)) {
        ElMessage({
          type: "warning",
          message: "手机号格式不对"
        });
        return;
      }
      sendVCode();
    };

    const onLoginClick = () => {
      if (state.currentTab === 1) {
        if (state.account.trim() === "") {
          ElMessage({
            type: "warning",
            message: "请输入账号"
          });
          return;
        }
        if (state.password.trim() === "") {
          ElMessage({
            type: "warning",
            message: "请输入密码"
          });
          return;
        }
        if (state.vcode.trim() === "") {
          ElMessage({
            type: "warning",
            message: "请输入验证码"
          });
          return;
        }
        passwordLogin();
      } else {
        if (state.mobile.trim() === "") {
          ElMessage({
            type: "warning",
            message: "请输入手机号"
          });
          return;
        }
        if (!commonUtil.checkMobile(state.mobile)) {
          ElMessage({
            type: "warning",
            message: "手机号格式不对"
          });
          return;
        }
        if (state.vcode.trim() === "") {
          ElMessage({
            type: "warning",
            message: "请输入验证码"
          });
          return;
        }
        mobileLogin();
      }
    };

    const onChangeLoginTypeClick = () => {
      state.qrcodeLogin = !state.qrcodeLogin;
    };

    const startSendVCodeTimer = () => {
      clearSendVCodeTimer();
      state.sendVCodeCD = 60;
      sendVCodeTimer = setInterval(updateVCodeTimer, 1000);
    };

    const updateVCodeTimer = () => {
      state.sendVCodeCD--;
    };

    const clearSendVCodeTimer = () => {
      if (sendVCodeTimer) {
        clearInterval(sendVCodeTimer);
        sendVCodeTimer = null;
      }
    };

    const getVCode = async () => {
      try {
        state.isRefeshVCodeLoading = true;
        const response = await authApi.getVCode();
        if (response.ret === 0) {
          state.vcodeImage = response.data.img;
          state.uuid = response.data.uuid;
        }
      } finally {
        state.isRefeshVCodeLoading = false;
      }
    };

    const sendVCode = async () => {
      try {
        state.isSendVCodeLoading = true;
        const response = await authApi.sendVCode(state.mobile);
        if (response.ret === 0) {
          startSendVCodeTimer();
        }
        else {
          ElMessage.error(response.msg);
        }
      } finally {
        state.isSendVCodeLoading = false;
      }
    };

    const passwordLogin = async () => {
      try {
        state.isLoginLoading = true;
        const response = await authApi.passwordLogin(state.account, state.password, state.uuid, state.vcode);
        if (response.ret === 0) {
          loginSuccess(response.user);
        } else if (response.ret === 1) {
          ElMessage.error('密码错误');
          getVCode();
        }
      } finally {
        state.isLoginLoading = false;
      }
    };

    const mobileLogin = async () => {
      try {
        state.isLoginLoading = true;
        const response = await authApi.mobileLogin(state.mobile, state.vcode);
        if (response.ret === 0) {
          loginSuccess(response.user);
        }
      } finally {
        state.isLoginLoading = false;
      }
    };

    const loginSuccess = (data) => {
      ready.value = true;
      userInfo.value = data.user;
      permissions.value = data.permissions;
    };

    const redirect = () => {
      let target = "";
      if (permissions.value.includes("general:view")) {
        target = "/general";
      } else {
        const prods = permissions.value.filter(perm => perm.indexOf("prod:view:") >= 0);
        if (prods.length > 0) {
          const prodId = prods[0].split(":")[2];
          target = `/product?id=${prodId}`;
        } else {
          const projs = permissions.value.filter(perm => perm.indexOf("proj:view:") >= 0);
          if (projs.length > 0) {
            const projId = projs[0].split(":")[2];
            target = `/project?id=${projId}`;
          }
        }
      }
      router.replace(target);
    };

    watchEffect(() => {
      if (ready.value) {
        if (Object.keys(userInfo.value).length > 0) {
          redirect();
        } else {
          getVCode();
        }
      }
    });

    return {
      isLoading,
      style,
      tabs,
      ...toRefs(state),
      onTabClick,
      onVCodeClick,
      onSendVCodeClick,
      onLoginClick,
      onChangeLoginTypeClick
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/main.scss";

.login {
  @extend %center;
  width: 100%;
  height: 100%;
  background: var(--login-bg) no-repeat center center;
  background-size: cover;
}

.inner {
  position: relative;
  @extend %center;
  flex-shrink: 0;
  width: 1920px;
  height: 1080px;
  transform-origin: center center;
  overflow: hidden;
}

.logo {
  position: absolute;
  top: 24px;
  left: 32px;
}

.panel {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 420px;
  background-color: var(--main-background-color);
  box-shadow: 0 0 80px 0 rgba(255, 255, 255, 0.1);

  .line {
    width: 100%;
    height: 2px;
    background-color: var(--brand-color);
  }

  .title {
    @extend %center;
    align-self: center;
    width: 100%;
    height: 72px;

    .text {
      font-size: 20px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: var(--main-font-color);
    }
  }

  .tabs {
    position: relative;
    display: flex;
    width: 100%;
    height: 48px;

    .tab {
      flex: 1;
      height: 100%;
      font-size: 16px;
      font-family: MicrosoftYaHei;
      color: var(--main-font-color);
      line-height: 21px;
      transition: all 0.3s;

      &.current {
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: var(--brand-color);
      }
    }

    .tab-line {
      position: absolute;
      bottom: 0;
      left: 57px;
      width: 96px;
      height: 2px;
      background-color: var(--brand-color);
      transition: all 0.3s;

      &.right {
        left: 268px;
      }
    }
  }

  .field-title {
    margin-top: 27px;
    margin-left: 50px;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: var(--main-font-color);
    line-height: 19px;
  }

  .input {
    margin-top: 10px;
    margin-left: 50px;
    padding: 0 10px;
    width: 320px;
    height: 48px;
    background-color: var(--secondary-background-color);
    border-radius: 2px;
    box-sizing: border-box;
    border: 1px solid var(--secondary-border-color);
    font-size: 16px;
    color: var(--main-font-color);

    &.vcode-input {
      margin-top: 0;
      margin-left: 0;
      width: 164px;
    }
  }

  .field {
    @extend %horizontal;
    justify-content: space-between;
    margin-top: 10px;
    margin-left: 50px;
    width: 320px;
  }

  .btn-vcode {
    display: flex;
    margin-left: 8px;
    width: 148px;
    height: 48px;
    box-sizing: border-box;
    border: 1px solid var(--main-border-color);

    .image {
      width: 100%;
      height: 100%;
    }
  }

  .btn-send-vcode {
    margin-left: 8px;
    width: 148px;
    height: 48px;
    background-color: var(--brand-color);
    border-radius: 2px;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: var(--main-font-color);
  }

  .btn-login {
    align-self: center;
    margin-top: 44px;
    width: 320px;
    height: 48px;
    background-color: var(--brand-color);
    border-radius: 2px;
    box-sizing: border-box;
    font-size: 16px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #FFFFFF;
  }

  .qrcode {
    @extend %center;
    width: 100%;
    height: 360px;

    .image {
      width: 280px;
      height: 280px;
      background-color: #eeeeee;
    }
  }

  .btn-login-type {
    @extend %horizontal;
    align-self: flex-end;
    margin: 24px 0;
    margin-right: 32px;
    width: 142px;
    height: 32px;
    background-color: var(--secondary-background-color);
    background: linear-gradient(-45deg, transparent 20px, var(--secondary-background-color) 0);
    z-index: 2;

    .text {
      margin-left: 12px;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: var(--main-font-color);
    }
  }

  .corner {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;

    .icon {
      width: 64px;
      height: 64px;
    }
  }
}

.rights {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  margin: auto;
  font-size: 14px;
  font-family: MicrosoftYaHeiSemibold;
  color: rgba(255, 255, 255, 0.4);
  line-height: 20px;
  text-align: center;
}
</style>

