import request from "@/utils/request";

function getAXIntro() {
  return request({
    url: "datav/dashboard/ax_intro",
    method: "get"
  });
}

function getProductCategory() {
  return request({
    url: "datav/dashboard/product_category",
    method: "get"
  });
}

function getProductList() {
  return request({
    url: "datav/dashboard/product_list",
    method: "get"
  });
}

function getProductStat() {
  return request({
    url: "datav/dashboard/project_stat",
    method: "get"
  });
}

function getAnnouncementList() {
  return request({
    url: "datav/dashboard/announcement_list",
    method: "get"
  });
}

function getProjectList() {
  return request({
    url: "datav/dashboard/project_list",
    method: "get"
  });
}

function getProjectListByProductId(productId) {
  return request({
    url: "datav/dashboard/project_list_by_prod_id",
    method: "get",
    params: { product_id: productId }
  });
}



export default {
  getAXIntro,
  getProductCategory,
  getProductList,
  getProductStat,
  getAnnouncementList,
  getProjectList,
  getProjectListByProductId
}