import { reactive, computed, toRefs } from "vue";
import authApi from "@/apis/authApi";

let composition = null;

function useUser() {
    const state = reactive({
        ready: false,
        isLoading: false,
        userInfo: {},
        permissions: []
    });

    const checkSession = async () => {
        try {
            state.isLoading = true;
            const response = await authApi.checkSession();
            state.ready = true;
            if (response.ret === 0) {
                state.permissions = response.data.permissions;
                state.userInfo = response.data.user;
            }
        } finally {
            state.isLoading = false;
        }
    };
  
    checkSession();

    return {
        ...toRefs(state)
    };
}

export default {
    init() {
        if (!composition) {
            composition = useUser();
        }
        return composition;
    },
    destroy() {
        composition = null;
    }
}