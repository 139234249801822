import { createApp } from "vue";
import moment from "moment";
import ElementPlus from "element-plus";
import zh from "element-plus/es/locale/lang/zh-cn";
import "element-plus/dist/index.css"
import "swiper/swiper.scss";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./styles/theme.scss";
import "./styles/main.scss";

moment.locale("zh-cn");
const app = createApp(App);
app.use(store);
app.use(router);
app.use(ElementPlus, { locale: zh });
app.mount("#app");
