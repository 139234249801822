const styles = {
	dark: {
		mainBackgroundColor: "rgba(0, 0, 0, 0.9)",
		secondaryBackgroundColor: "rgba(255, 255, 255, 0.12)",
		thirdaryBackgroundColor: "rgba(255, 255, 255, 0.06)",
		mainFontColor: "#FFFFFF",
		secondaryFontColor: "#999999",
		mainBorderColor: "rgba(255, 255, 255, 0.4)",
		secondaryBorderColor: "rgba(255, 255, 255, 0.1)",
		tipFontColor: "#666666",
		brandColor: "#FF8517",
		successColor: "#3DE85E",
		warnColor: "#FFBA27",
		dangerColor: "#EF2A2A"
	},
	light: {
		mainBackgroundColor: "rgba(255, 255, 255, 0.8)",
		secondaryBackgroundColor: "rgab(0, 0, 0, 0.04)",
		thirdaryBackgroundColor: "rgba(0, 0, 0, 0.02)",
		mainFontColor:"#333333",
		secondaryFontColor: "#666666",
		mainBorderColor: "rgba(0, 0, 0, 0.2)",
		secondaryBorderColor: "rgba(0, 0, 0, 0.1)",
		tipFontColor: "#999999",
		brandColor: "#F5661B",
		successColor: "#2DDF50",
		warnColor: "#FFBA27",
		dangerColor: "#E83131"
	}
}

const icons = {
	dark: {
		timeBg: "/images/time-bg-dark.png",
		date: "/images/date-dark.svg",
		time: "/images/time-dark.svg",
		info: "/images/info-dark.svg",
		pie: "/images/pie-dark.svg",
		list: "/images/list-dark.svg",
		bar: "/images/bar-dark.svg",
		productInfo: "/images/product-info-dark.svg",
		play: "/images/play-dark.svg",
		map: "/images/map-dark.svg",
		rate: "/images/rate-dark.svg",
		projectInfo: "/images/project-info-dark.svg",
		landArea: "/images/land-area-dark.svg",
		countArea: "/images/count-area-dark.svg",
		buildingArea: "/images/building-area-dark.svg",
		saleArea: "/images/sale-area-dark.svg",
		alert: "/images/alert-dark.svg",
		productStatus: "/images/product-status-dark.svg",
		productIncome: "/images/product-income-dark.svg",
		clock: "/images/clock-dark.svg",
		projectIntroduction: "/images/project-introduction-dark.svg",
		folder: "/images/folder-dark.svg",
		risk: "/images/risk-dark.svg",
		projectCount: "/images/project-count-dark.svg",
		returnMoney: "/images/return-money-dark.svg",
		income: "/images/income-dark.svg",
		discussFinish: "/images/discuss-finish-dark.svg",
		discussRunning: "/images/discuss-running-dark.svg",
		discussWaiting: "/images/discuss-waiting-dark.svg",
		raiseFinish: "/images/raise-finish-dark.svg",
		raiseRunning: "/images/raise-running-dark.svg",
		raiseWaiting: "/images/raise-waiting-dark.svg",
		investFinish: "/images/invest-finish-dark.svg",
		investRunning: "/images/invest-running-dark.svg",
		investWaiting: "/images/invest-waiting-dark.svg",
		manageFinish: "/images/manage-finish-dark.svg",
		manageRunning: "/images/manage-running-dark.svg",
		manageWaiting: "/images/manage-waiting-dark.svg",
		exitFinish: "/images/exit-finish-dark.svg",
		exitRunning: "/images/exit-running-dark.svg",
		exitWaiting: "/images/exit-waiting-dark.svg",
		running: "/images/running-dark.svg",
		waiting: "/images/waiting-dark.svg",
		projectSeq: "/images/project-seq-dark.svg",
		projectTarget: "/images/project-target-dark.svg",
		board: "/images/board-dark.svg",
		calendar: "/images/calendar-dark.svg",
		investment: "/images/investment-dark.svg",
		financing: "/images/financing-dark.svg",
		investors: "/images/investors-dark.svg",
		right: "/images/right-dark.svg",
		timeline: "/images/timeline-dark.svg",
		manage: "/images/manage-dark.svg",
		star: "/images/star-dark.svg",
		cost: "/images/cost-dark.svg",
		coin: "/images/coin-dark.svg",
		coinActive: "/images/coin-active-dark.svg",
		balance: "/images/balance-dark.svg",
		available: "/images/available-dark.svg",
		unavailable: "/images/unavailable-dark.svg",
		loan: "/images/loan-dark.svg",
		inandout: "/images/inandout-dark.svg",
		apartpay: "/images/apartpay-dark.svg",
		empty: "/images/empty-dark.svg",
		changeTheme: "/images/change-theme-dark.svg"
	},
	light: {
		timeBg: "/images/time-bg-light.png",
		date: "/images/date-light.svg",
		time: "/images/time-light.svg",
		info: "/images/info-light.svg",
		pie: "/images/pie-light.svg",
		list: "/images/list-light.svg",
		bar: "/images/bar-light.svg",
		productInfo: "/images/product-info-light.svg",
		play: "/images/play-light.svg",
		map: "/images/map-light.svg",
		rate: "/images/rate-light.svg",
		projectInfo: "/images/project-info-light.svg",
		landArea: "/images/land-area-light.svg",
		countArea: "/images/count-area-light.svg",
		buildingArea: "/images/building-area-light.svg",
		saleArea: "/images/sale-area-light.svg",
		alert: "/images/alert-light.svg",
		productStatus: "/images/product-status-light.svg",
		productIncome: "/images/product-income-light.svg",
		clock: "/images/clock-light.svg",
		projectIntroduction: "/images/project-introduction-light.svg",
		folder: "/images/folder-light.svg",
		risk: "/images/risk-light.svg",
		projectCount: "/images/project-count-light.svg",
		returnMoney: "/images/return-money-light.svg",
		income: "/images/income-light.svg",
		discussFinish: "/images/discuss-finish-light.svg",
		discussRunning: "/images/discuss-running-light.svg",
		discussWaiting: "/images/discuss-waiting-light.svg",
		raiseFinish: "/images/raise-finish-light.svg",
		raiseRunning: "/images/raise-running-light.svg",
		raiseWaiting: "/images/raise-waiting-light.svg",
		investFinish: "/images/invest-finish-light.svg",
		investRunning: "/images/invest-running-light.svg",
		investWaiting: "/images/invest-waiting-light.svg",
		manageFinish: "/images/manage-finish-light.svg",
		manageRunning: "/images/manage-running-light.svg",
		manageWaiting: "/images/manage-waiting-light.svg",
		exitFinish: "/images/exit-finish-light.svg",
		exitRunning: "/images/exit-running-light.svg",
		exitWaiting: "/images/exit-waiting-light.svg",
		running: "/images/running-light.svg",
		waiting: "/images/waiting-light.svg",
		projectSeq: "/images/project-seq-light.svg",
		projectTarget: "/images/project-target-light.svg",
		board: "/images/board-light.svg",
		calendar: "/images/calendar-light.svg",
		investment: "/images/investment-light.svg",
		financing: "/images/financing-light.svg",
		investors: "/images/investors-light.svg",
		right: "/images/right-light.svg",
		timeline: "/images/timeline-light.svg",
		manage: "/images/manage-light.svg",
		star: "/images/star-light.svg",
		cost: "/images/cost-light.svg",
		coin: "/images/coin-light.svg",
		coinActive: "/images/coin-active-light.svg",
		balance: "/images/balance-light.svg",
		available: "/images/available-light.svg",
		unavailable: "/images/unavailable-light.svg",
		loan: "/images/loan-light.svg",
		inandout: "/images/inandout-light.svg",
		apartpay: "/images/apartpay-light.svg",
		empty: "/images/empty-light.svg",
		changeTheme: "/images/change-theme-light.svg"
	}
}

export default {
	namespaced: true,
	state: () => ({
		name: "dark"
	}),
	getters: {
		mainBackgroundColor(state) {
			return styles[state.name].mainBackgroundColor;
		},
		secondaryBackgroundColor(state) {
			return styles[state.name].secondaryBackgroundColor;
		},
		thirdaryBackgroundColor(state) {
			return styles[state.name].thirdaryBackgroundColor;
		},
		mainFontColor(state) {
			return styles[state.name].mainFontColor;
		},
		secondaryFontColor(state) {
			return styles[state.name].secondaryFontColor;
		},
		mainBorderColor(state) {
			return styles[state.name].mainBorderColor;
		},
		secondaryBorderColor(state) {
			return styles[state.name].secondaryBorderColor;
		},
		tipFontColor(state) {
			return styles[state.name].tipFontColor;
		},
		brandColor(state) {
			return styles[state.name].brandColor;
		},
		successColor(state) {
			return styles[state.name].successColor;
		},
		warnColor(state) {
			return styles[state.name].warnColor;
		},
		dangerColor(state) {
			return styles[state.name].dangerColor;
		},
		timeBgIcon(state) {
			return icons[state.name].timeBg;
		},
		dateIcon(state) {
			return icons[state.name].date;
		},
		timeIcon(state) {
			return icons[state.name].time;
		},
		infoIcon(state) {
			return icons[state.name].info;
		},
		pieIcon(state) {
			return icons[state.name].pie;
		},
		listIcon(state) {
			return icons[state.name].list;
		},
		barIcon(state) {
			return icons[state.name].bar;
		},
		productInfoIcon(state) {
			return icons[state.name].productInfo;
		},
		playIcon(state) {
			return icons[state.name].play;
		},
		mapIcon(state) {
			return icons[state.name].map;
		},
		rateIcon(state) {
			return icons[state.name].rate;
		},
		projectInfoIcon(state) {
			return icons[state.name].projectInfo;
		},
		landAreaIcon(state) {
			return icons[state.name].landArea;
		},
		countAreaIcon(state) {
			return icons[state.name].countArea;
		},
		buildingAreaIcon(state) {
			return icons[state.name].buildingArea;
		},
		saleAreaIcon(state) {
			return icons[state.name].saleArea;
		},
		alertIcon(state) {
			return icons[state.name].alert;
		},
		productStatusIcon(state) {
			return icons[state.name].productStatus;
		},
		productIncomeIcon(state) {
			return icons[state.name].productIncome;
		},
		clockIcon(state) {
			return icons[state.name].clock;
		},
		projectIntroductionIcon(state) {
			return icons[state.name].projectIntroduction;
		},
		folderIcon(state) {
			return icons[state.name].folder;
		},
		riskIcon(state) {
			return icons[state.name].risk;
		},
		projectCountIcon(state) {
			return icons[state.name].projectCount;
		},
		returnMoneyIcon(state) {
			return icons[state.name].returnMoney;
		},
		incomeIcon(state) {
			return icons[state.name].income;
		},
		discussFinishIcon(state) {
			return icons[state.name].discussFinish;
		},
		discussRunningIcon(state) {
			return icons[state.name].discussRunning;
		},
		discussWaitingIcon(state) {
			return icons[state.name].discussWaiting;
		},
		raiseFinishIcon(state) {
			return icons[state.name].raiseFinish;
		},
		raiseRunningIcon(state) {
			return icons[state.name].raiseRunning;
		},
		raiseWaitingIcon(state) {
			return icons[state.name].raiseWaiting;
		},
		investFinishIcon(state) {
			return icons[state.name].investFinish;
		},
		investRunningIcon(state) {
			return icons[state.name].investRunning;
		},
		investWaitingIcon(state) {
			return icons[state.name].investWaiting;
		},
		manageFinishIcon(state) {
			return icons[state.name].manageFinish;
		},
		manageRunningIcon(state) {
			return icons[state.name].manageRunning;
		},
		manageWaitingIcon(state) {
			return icons[state.name].manageWaiting;
		},
		exitFinishIcon(state) {
			return icons[state.name].exitFinish;
		},
		exitRunningIcon(state) {
			return icons[state.name].exitRunning;
		},
		exitWaitingIcon(state) {
			return icons[state.name].exitWaiting;
		},
		runningIcon(state) {
			return icons[state.name].running;
		},
		waitingIcon(state) {
			return icons[state.name].waiting;
		},
		projectSeqIcon(state) {
			return icons[state.name].projectSeq;
		},
		projectTargetIcon(state) {
			return icons[state.name].projectTarget;
		},
		boardIcon(state) {
			return icons[state.name].board;
		},
		calendarIcon(state) {
			return icons[state.name].calendar;
		},
		investmentIcon(state) {
			return icons[state.name].investment;
		},
		financingIcon(state) {
			return icons[state.name].financing;
		},
		investorsIcon(state) {
			return icons[state.name].investors;
		},
		rightIcon(state) {
			return icons[state.name].right;
		},
		timelineIcon(state) {
			return icons[state.name].timeline;
		},
		manageIcon(state) {
			return icons[state.name].manage;
		},
		starIcon(state) {
			return icons[state.name].star;
		},
		costIcon(state) {
			return icons[state.name].cost;
		},
		coinIcon(state) {
			return icons[state.name].coin;
		},
		coinActiveIcon(state) {
			return icons[state.name].coinActive;
		},
		balanceIcon(state) {
			return icons[state.name].balance;
		},
		availableIcon(state) {
			return icons[state.name].available;
		},
		unavailableIcon(state) {
			return icons[state.name].unavailable;
		},
		loanIcon(state) {
			return icons[state.name].loan;
		},
		inandoutIcon(state) {
			return icons[state.name].inandout;
		},
		apartpayIcon(state) {
			return icons[state.name].apartpay;
		},
		emptyIcon(state) {
			return icons[state.name].empty;
		},
		changeThemeIcon(state) {
			return icons[state.name].changeTheme;
		}
	},
	mutations: {
		changeTheme(state) {
			const name = state.name === "dark" ? "light" : "dark";
			state.name = name;
		}
	}
}
