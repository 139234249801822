<template>
  <div class="logo">
			<img class="icon" src="/images/logo.png" />
			<div class="separator" />
			<span class="text">{{title}}</span>
	</div>
</template>

<script>

export default {
  name: "Logo",
	props: ["title"]
}
</script>

<style lang="scss" scoped>
@import "@/styles/main.scss";

.logo {
  @extend %horizontal;

  .icon {
    width: 144px;
    height: 28px;
  }

  .separator {
    margin: 0 16px;
    width: 1px;
    height: 36px;
    background-color: var(--main-border-color);
  }

  .text {
    max-width: 800px;
    font-size: 24px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: var(--main-font-color);
    line-height: 32px;
    letter-spacing: 4px;
    @include ellipsis();
  }
}
</style>

