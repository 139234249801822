<template>
  <div class="title-bar">
    <div class="left">
      <img class="icon" :src="icon" />
      <span class="title">{{title}}</span>
    </div>
    <div class="right">
      <slot name="right" />
    </div>
	</div>
</template>

<script>

export default {
  name: "TitleBar",
	props: ["icon", "title"]
}
</script>

<style lang="scss" scoped>
@import "@/styles/main.scss";

.title-bar {
  @extend %horizontal;
  justify-content: space-between;
  width: 100%;
  height: 36px;

  .left {
    @extend %horizontal;
    margin-left: 12px;
  }

  .icon {
    width: 18px;
    height: 18px;
  }

  .title {
    margin-left: 4px;
    font-size: 14px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: var(--main-font-color);
  }

  .right {
    @extend %horizontal;
    margin-right: 12px;
  }
}
</style>

