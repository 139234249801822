<template>
  <div class="general-right">
    <div class="section top">
      <title-bar :icon="rateIcon" title="项目分布比例" />
      <div class="separator" />
      <div class="body" id="rate-chart" v-if="Object.keys(productStat).length > 0" />
      <div class="body" v-else>
        <empty />
      </div>
    </div>
    <div class="section middle">
      <title-bar :icon="barIcon" title="项目分布情况" />
      <div class="separator" />
      <div class="body" id="count-chart" v-if="Object.keys(productStat).length > 0" />
      <div class="body" v-else>
        <empty />
      </div>
    </div>
    <div class="section bottom">
      <title-bar :icon="projectInfoIcon" title="项目信息">
        <template v-slot:right>
          <el-select class="selector" size="mini" placeholder="选择项目" v-model="projectId">
            <el-option :label="project.projectName" :value="project.projectId" v-for="project in projectList" :key="project.projectId" @click="onProjectClick(project)" />
          </el-select>
        </template>
      </title-bar>
      <div class="separator" />
      <div class="body" v-if="projectList.length > 0">
        <swiper class="swiper" :modules="modules" :autoplay="{delay: 5000}" :key="productId" @slideChange="onProjectChange">
          <swiper-slide class="product" v-for="project in projectList" :key="project.projectId">
            <div class="field">
              <span class="title">项目名称</span>
              <span class="text">{{project.projectName}}</span>
            </div>
            <div class="field">
              <span class="title">建设单位</span>
              <span class="text">{{project.buildingCompany}}</span>
            </div>
            <div class="field">
              <span class="title">建设地址</span>
              <span class="text">{{project.buildingAddress}}</span>
            </div>
            <div class="datas">
              <div class="item">
                <div class="icon">
                  <img class="image" :src="landAreaIcon" />
                </div>
                <div class="info">
                  <span class="title">用地面积</span>
                  <span class="text">{{project.landArea}}㎡</span>
                </div>
              </div>
              <div class="item">
                <div class="icon">
                  <img class="image" :src="countAreaIcon" />
                </div>
                <div class="info">
                  <span class="title">计容面积</span>
                  <span class="text">{{project.volumnArea}}㎡</span>
                </div>
              </div>
              <div class="item">
                <div class="icon">
                  <img class="image" :src="buildingAreaIcon" />
                </div>
                <div class="info">
                  <span class="title">总建筑面积</span>
                  <span class="text">{{project.totalBuildingArea}}㎡</span>
                </div>
              </div>
              <div class="item">
                <div class="icon">
                  <img class="image" :src="saleAreaIcon" />
                </div>
                <div class="info">
                  <span class="title">计容可售面积</span>
                  <span class="text">{{project.volumnSaleArea}}㎡</span>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div class="body" v-else>
        <empty />
      </div>
    </div>
	</div>
</template>

<script>
import { computed, watchEffect, onMounted, onBeforeUnmount, nextTick } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import * as echarts from "echarts";
import { Autoplay  } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import TitleBar from "@/components/common/TitleBar";
import Empty from "@/components/common/Empty";
import general from "@/compositions/general";
import generalCharts from "@/charts/generalCharts";

export default {
  name: "GeneralRight",
  components: {
    Swiper,
    SwiperSlide,
    TitleBar,
    Empty
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const themeName = computed(() => store.state.theme.name);
    const rateIcon = computed(() => store.getters["theme/rateIcon"]);
    const barIcon = computed(() => store.getters["theme/barIcon"]);
    const projectInfoIcon = computed(() => store.getters["theme/projectInfoIcon"]);
    const landAreaIcon = computed(() => store.getters["theme/landAreaIcon"]);
    const countAreaIcon = computed(() => store.getters["theme/countAreaIcon"]);
    const buildingAreaIcon = computed(() => store.getters["theme/buildingAreaIcon"]);
    const saleAreaIcon = computed(() => store.getters["theme/saleAreaIcon"]);

    const data = general.init();
    const { productStat, productId, projectList, projectId } = data;

    let rateChart = null;
    let countChart = null;

    onMounted(() => {
      watchEffect(() => {
        if (themeName.value && Object.keys(productStat.value).length > 0) {
          nextTick(() => {
            if (!rateChart) {
              rateChart = echarts.init(document.getElementById("rate-chart"));
            }
            if (!countChart) {
              countChart = echarts.init(document.getElementById("count-chart"));
            }
            generalCharts.updateRateChart(rateChart, productStat, store);
            generalCharts.updateCountChart(countChart, productStat, store);
          });
        } else {
          if (rateChart) {
            rateChart.dispose();
            rateChart = null;
          }
          if (countChart) {
            countChart.dispose();
            countChart = null;
          }
        }
      });
    });

    const onProjectChange = (event) => {
      const index = event.realIndex;
      projectId.value = projectList.value[index].projectId;
    };

    const onProjectClick = (project) => {
      router.push({
        path: "/project", 
        query: { 
          id: project.projectId 
        }
      });
    };

    onBeforeUnmount(() => {
      if (rateChart) {
        rateChart.dispose();
        rateChart = null;
      }
      if (countChart) {
        countChart.dispose();
        countChart = null;
      }
    });

    return {
      rateIcon,
      barIcon,
      projectInfoIcon,
      landAreaIcon,
      countAreaIcon,
      buildingAreaIcon,
      saleAreaIcon,
      projectList,
      productStat,
      productId,
      projectId,
      modules: [Autoplay],
      onProjectChange,
      onProjectClick
    };
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/main.scss";

.general-right {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.section {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  margin-right: 16px;
  width: 376px;
  background-color: var(--main-background-color);

  &.top {
    height: 318px;
  }

  &.middle {
    height: 319px;
  }

  &.bottom {
    flex: 1;
  }

  .selector {
    width: 176px;
  }

  .body {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;

    .swiper {
      width: 100%;
      height: 100%;
    }

    .project {
      width: 100%;
      height: 273px;
    }

    .field {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;

      &:first-child {
        margin-top: 12px;
      }

      &:last-child {
        margin-top: 5px;
      }

      .title {
        margin-left: 16px;
        width: 70px;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: var(--secondary-font-color);
        line-height: 20px;
      }

      .text {
        margin-left: 14px;
        margin-right: 8px;
        width: 268px;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: var(--main-font-color);
        line-height: 20px;
        @include ellipsis(3);
      }
    }

    .datas {
      display: flex;
      flex-wrap: wrap;

      .item {
        @extend %horizontal;
        width: 188px;
        height: 72px;

        .icon {
          @extend %center;
          margin-left: 12px;
          width: 48px;
          height: 48px;
          background-color: var(--secondary-background-color);
          border-radius: 50%;

          .image {
            width: 28px;
            height: 28px;
          }
        }

        .info {
          display: flex;
          flex-direction: column;
          margin-left: 8px;

          .title {
            font-size: 12px;
            font-family: MicrosoftYaHei;
            color: var(--secondary-font-color);
            line-height: 16px;
          }

          .text {
            font-size: 18px;
            font-family: JetBrainsMonoRoman-Medium, JetBrainsMonoRoman;
            font-weight: 500;
            color: var(--main-font-color);
            line-height: 23px;
          }
        }
      }
    }
  }
}
</style>
