<template>
  <div class="top">
    <title-bar :icon="mapIcon" title="项目分布地图" />
    <div class="separator" />
    <div class="datas">
      <div class="item blue">
        <div class="line" />
        <template v-if="Object.keys(productStat).length > 0">
          <span class="title">项目总数</span>
          <div class="value">
            <span class="num">{{productStat.totalProject}}</span>
            <span class="unit">个</span>
          </div>
        </template>
        <empty v-else />
      </div>
      <div class="item orange">
        <div class="line" />
        <template v-if="Object.keys(productStat).length > 0">
          <span class="title">分布区域</span>
          <div class="value">
            <span class="num">{{productStat.provinceCount}}</span>
            <span class="unit">个</span>
          </div>
        </template>
        <empty v-else />
      </div>
      <div class="item green">
        <div class="line" />
        <template v-if="Object.keys(productStat).length > 0">
          <span class="title">项目规模</span>
          <div class="value">
            <span class="num">{{productStat.projectScale}}</span>
            <span class="unit">亿</span>
          </div>
        </template>
        <empty v-else />
      </div>
    </div>
  </div>
  <div class="bottom">
    <div class="separator" />
    <div class="notice" v-if="announcementList.length > 0">
      <img class="icon" src="/images/notice.svg" />
      <marquee class="content" :pauseOnHover="true">
        <div class="item" v-for="(announcement, index) in announcementList" :key="index">
          <span class="text">【{{announcement.createTime}} {{announcement.type === 0 ? announcement.productName : announcement.projectName}}】{{announcement.content}}</span>
        </div>
      </marquee>
    </div>
    <swiper class="swiper" :modules="modules" :autoplay="{delay: 3000, disableOnInteraction: false}" :slides-per-view="4.33">
      <swiper-slide class="swiper-slide" v-for="(picture, index) in pictures" :key="index">
        <img class="picture" :src="picture" />
      </swiper-slide>
      <template v-if="pictures.length < 5">
        <swiper-slide class="swiper-slide" v-for="index in 5 - pictures.length" :key="`placeholder-${index}`">
          <empty class="picture" />
        </swiper-slide>
      </template>
    </swiper>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import Marquee from "vue3-marquee";
import { Autoplay  } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import general from "@/compositions/general";
import TitleBar from "@/components/common/TitleBar";
import Empty from "@/components/common/Empty";

export default {
  name: "GeneralMiddle",
  components: {
    Marquee,
    Swiper,
    SwiperSlide,
    TitleBar,
    Empty
  },
  setup() {
    const store = useStore();
    const mapIcon = computed(() => store.getters["theme/mapIcon"]);

    const data = general.init();
    const { productStat, announcementList, project, projectId } = data;

    const pictures = computed(() => {
      return project.value ? project.value.pictures : [];
    });

    return {
      mapIcon,
      productStat,
      announcementList,
      projectId,
      pictures,
      modules: [Autoplay]
    };
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/main.scss";

.top {
  position: absolute;
  top: 88px;
  left: 400px;
  display: flex;
  flex-direction: column;
  width: 1104px;

  .datas {
    display: flex;
    margin-top: 16px;

    .item {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: 16px;
      width: 160px;
      height: 100px;
      background-color: var(--main-background-color);

      .line {
        position: absolute;
        top: 0;
        left: 0;
        width: 2px;
        height: 100%;
      }

      .title {
        margin-left: 24px;
        font-size: 16px;
        font-family: MicrosoftYaHei;
        color: var(--main-font-color);
        line-height: 21px;
      }

      .value {
        display: flex;
        margin-left: 24px;

        .num {
          font-size: 36px;
          font-family: JetBrainsMonoRoman-Bold, JetBrainsMonoRoman;
          font-weight: bold;
          line-height: 48px;
        }

        .unit {
          margin-top: 16px;
          margin-left: 4px;
          font-size: 18px;
          font-family: MicrosoftYaHei;
          color: var(--secondary-font-color);
          line-height: 24px;
        }
      }

      &.blue {
        .line {
          background-color: #00A3FF;
        }

        .value {
          .num {
            color: #00A3FF;
          }
        }
      }

      &.orange {
        .line {
          background-color: var(--brand-color);
        }

        .value {
          .num {
            color: var(--brand-color);
          }
        }
      }

      &.green {
        .line {
          background-color: #1AD9AB;
        }

        .value {
          .num {
            color: #1AD9AB;
          }
        }
      }
    }
  }
}

.bottom {
  position: absolute;
  bottom: 16px;
  left: 400px;
  display: flex;
  flex-direction: column;
  width: 1104px;

  .notice {
    @extend %horizontal;
    width: 100%;
    height: 40px;
    background: linear-gradient(90deg, rgba(255, 186, 39, 0) 0%, rgba(255, 186, 39, 0.4) 12%, rgba(255, 186, 39, 0.8) 51%, rgba(255, 186, 39, 0.4) 85%, rgba(255, 186, 39, 0) 100%);

    .icon {
      margin: 12px;
      width: 24px;
      height: 24px;
    }

    .content {
      flex: 1;
      min-width: 0;
      height: 100%;

      .item {
        @extend %horizontal;
        height: 40px;

        .text {
          margin: 0 16px;
          font-size: 16px;
          font-family: MicrosoftYaHeiSemibold;
          color: var(--main-font-color);
        }
      }
    }
  }

  .swiper {
    width: 100%;
    height: 236px;

    .swiper-slide {
      width: 240px;
      height: 236px;

      &:first-child {
        margin-left: 16px;
      }

      &:last-child {
        margin-right: 16px;
      }

      .picture {
        margin-top: 32px;
        width: 240px;
        height: 180px;
        object-fit: cover;
      }
    }
  }
}
</style>
