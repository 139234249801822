<template>
  <div class="general-header">
    <img class="time-bg" :src="timeBgIcon" />
    <div class="separator" />
    <logo class="logo" title="综合看板" />
    <div class="clock">
      <clock />
    </div>
    <commands />
	</div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import Logo from "@/components/common/Logo";
import Clock from "@/components/common/Clock";
import Commands from "@/components/common/Commands";

export default {
  name: "GeneralHeader",
  components: {
    Logo,
    Clock,
    Commands
  },
  setup() {
    const store = useStore();
    const timeBgIcon = computed(() => store.getters["theme/timeBgIcon"]);

    return {
      timeBgIcon
    };
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/main.scss";

.general-header {
  position: relative;
  @extend %horizontal;
  justify-content: space-between;
  width: 100%;
  height: 80px;

  .time-bg {
    position: absolute;
    top: 0;
    right: 0;
    width: 509px;
    height: 80px;
  }

  .separator {
    position: absolute;
    top: 71px;
    left: 16px;
    width: 1888px;
    height: 1px;
    background-color: var(--main-border-color);
  }

  .logo {
    margin-left: 32px;
  }

  .clock {
    display: flex;
    margin-right: 24px;
  }
}
</style>
