import { reactive, computed, watch, watchEffect, toRefs } from "vue";
import { useRouter } from "vue-router";
import moment from "moment";
import user from "@/compositions/user";
import projectApi from "@/apis/projectApi";

let composition = null;

function useProject() {
    const router = useRouter();

    const { ready, userInfo, permissions } = user.init();

    const state = reactive({
        selectProjectId: null,
        projectId: null,
        years: [],
        year: (new Date()).getFullYear(),
        productTree: [],
        versionList1: [],
        versionList2: [],
        periodList: [],
        date1: moment().format("YYYY-MM-DD"),
        versionId1: null,
        date2: moment().format("YYYY-MM-DD"),
        versionId2: null,
        period: null,
        mainIndexData: {},
        saleItemData: {},
        investorMainIndexData: {},
        axMainIndexData: {},
        announcementList: [],
        projectIntroduction: {},
        operateCashFlowTotal: {},
        financeCashFlowTotal: {},
        investorCashFlowTotal: {},
        axCashFlowTotal: {},
        projectVolumnSaleData: {},
        projectSaleData: {},
        projectAccountDetail: {},
        yearSaleFinishData: {},
        yearRepaymentData: {},
        yearPaymentData: {},
        bottomTabIndex: 0
    });

    const hasPermission = computed(() => {
        const perm = `proj:view:${state.projectId}`;
        return permissions.value.includes(perm);
    });

    const currentProject = computed(() => {
        if (!state.projectId) {
            return null;
        }
        for (const product of state.productTree) {
            for (const project of product.children) {
                if (project.id === state.projectId) {
                    return project;
                }
            }
        }
        return null;
    });

    const version1 = computed(() => {
        for (const version of state.versionList1) {
            if (version.id === state.versionId1) {
                return version;
            }
        }
        return null;
    });

    const version2 = computed(() => {
        for (const version of state.versionList2) {
            if (version.id === state.versionId2) {
                return version;
            }
        }
        return null;
    });

    const getProductTree = async () => {
        try {
            const response = await projectApi.getProductTree();
            if (response.ret === 0) {
                response.data.forEach(product => {
                    product.id = `product-${product.id}`;
                    product.children.forEach(project => {
                        project.id = `project-${project.id}`;
                    });
                });
                state.productTree = response.data;
                state.productTree.unshift({ id: 0, name: "综合看板", children: [] });
            }
        } catch (error) {
            console.warn(error);
        }
    };

    const getVersionList1 = async () => {
        try {
            const response = await projectApi.getVersionList(state.projectId, 0);
            if (response.ret === 0) {
                state.versionList1 = response.data;
                if (state.versionList1.length > 0) {
                    state.versionId1 = state.versionList1[0].id;
                }
            }
        } catch (error) {
            console.warn(error);
        }
    };

    const getVersionList2 = async () => {
        try {
            const response = await projectApi.getVersionList(state.projectId, 1);
            if (response.ret === 0) {
                state.versionList2 = response.data;
                if (state.versionList2.length > 0) {
                    state.versionId2 = state.versionList2[0].id;
                }
            }
        } catch (error) {
            console.warn(error);
        }
    };

    const getPeriodList = async () => {
        try {
            const response = await projectApi.getPeriodList(state.projectId, state.versionId1);
            state.periodList = response.data.periods;
            if (!state.period && state.periodList.length > 0) {
                state.period = state.periodList[0];
            }

            state.years = response.data.years;
            if (state.years.length > 0 && state.years.indexOf(state.year) === -1) {
                state.year = state.years[0];
            }
        } catch (error) {
            console.warn(error);
        }
    };

    const getMainIndexData = async () => {
        try {
            const response = await projectApi.getMainIndexData(state.projectId, state.versionId1, state.versionId2, state.period, state.date1, state.date2);
            state.mainIndexData = response.data;
        } catch (error) {
            console.warn(error);
        }
    };

    const getSaleItemData = async () => {
        try {
            const response = await projectApi.getSaleItemData(state.projectId, state.versionId2, state.date2);
            state.saleItemData = response.data;
        } catch (error) {
            console.warn(error);
        }
    };

    const getInvestorMainIndexData = async () => {
        try {
            const response = await projectApi.getInvestorMainIndexData(state.projectId, state.versionId1, state.versionId2, state.date1, state.date2);
            state.investorMainIndexData = response.data;
        } catch (error) {
            console.warn(error);
        }
    };

    const getAXMainIndexData = async () => {
        try {
            const response = await projectApi.getAXMainIndexData(state.projectId, state.versionId1, state.versionId2, state.date1, state.date2);
            if (response.ret === 0) {
                state.axMainIndexData = response.data;
            } else {
                state.axMainIndexData = {};
            }
        } catch (error) {
            console.warn(error);
        }
    };

    const getAnnouncementList = async () => {
        try {
            const response = await projectApi.getAnnouncementList(state.projectId);
            if (response.ret === 0) {
                state.announcementList = response.data;
            } else {
                state.announcementList = [];
            }
        } catch (error) {
            console.warn(error);
        }
    };

    const getProjectIntroduction = async () => {
        try {
            const response = await projectApi.getProjectIntroduction(state.projectId, state.versionId1, state.versionId2);
            state.projectIntroduction  = response.data;

            // if (state.projectIntroduction.startTime) {
            //     state.date1 = state.projectIntroduction.startTime;
            // }
            // if (state.projectIntroduction.endTime) {
            //     state.date2 = state.projectIntroduction.endTime;
            // }

        } catch (error) {
            console.warn(error);
        }
    };

    const getOperateCashFlowTotal = async () => {
        try {
            const response = await projectApi.getOperateCashFlowTotal(state.projectId, state.versionId1, state.versionId2, state.date1, state.date2);
            state.operateCashFlowTotal = response.data;
        } catch (error) {
            console.warn(error);
        }
    };

    const getFinanceCashFlowTotal = async () => {
        try {
            const response = await projectApi.getFinanceCashFlowTotal(state.projectId, state.versionId1, state.versionId2, state.date1, state.date2);
            state.financeCashFlowTotal = response.data;
        } catch (error) {
            console.warn(error);
        }
    };

    const getInvestorCashFlowTotal = async () => {
        try {
            const response = await projectApi.getInvestorCashFlowTotal(state.projectId, state.versionId1, state.versionId2, state.date1, state.date2);
            state.investorCashFlowTotal = response.data;
        } catch (error) {
            console.warn(error);
        }
    };

    const getAXCashFlowTotal = async () => {
        try {
            const response = await projectApi.getAXCashFlowTotal(state.projectId, state.versionId1, state.versionId2, state.date1, state.date2);
            if (response.ret === 0) {
                state.axCashFlowTotal = response.data;
            } else {
                state.axCashFlowTotal = {};
            }
        } catch (error) {
            console.warn(error);
        }
    };

    const getProjectVolumnSaleData = async () => {
        try {
            const response = await projectApi.getProjectVolumnSaleData(state.projectId, state.versionId1, state.versionId2, state.date1, state.date2);
            state.projectVolumnSaleData = response.data;
        } catch (error) {
            console.warn(error);
        }
    };

    const getProjectSaleData = async () => {
        try {
            const response = await projectApi.getProjectSaleData(state.projectId, state.versionId1, state.versionId2, state.date1, state.date2);
            state.projectSaleData = response.data;
        } catch (error) {
            console.warn(error);
        }
    };

    
    const getProjectAccountDetail = async () => {
        try {
            const response = await projectApi.getProjectAccountDetail(state.projectId, state.versionId2, state.date2);
            state.projectAccountDetail = response.data;
        } catch (error) {
            console.warn(error);
        }
    };

    const getYearSaleFinishData = async () => {
        try {
            const time = state.date2 != null ? state.date2 : state.year;
            const response = await projectApi.getYearSaleFinishData(state.projectId, time, state.versionId1, state.versionId2);
            state.yearSaleFinishData = response.data;
        } catch (error) {
            console.warn(error);
        }
    };

    const getYearRepaymentData = async () => {
        try {
            const time = state.date2 != null ? state.date2 : state.year;
            const response = await projectApi.getYearRepaymentData(state.projectId, time, state.versionId1, state.versionId2);
            state.yearRepaymentData = response.data;
        } catch (error) {
            console.warn(error);
        }
    };

    const getYearPaymentData = async () => {
        try {
            const time = state.date2 != null ? state.date2 : state.year;
            const response = await projectApi.getYearPaymentData(state.projectId, time, state.versionId1, state.versionId2);
            state.yearPaymentData = response.data;
        } catch (error) {
            console.warn(error);
        }
    };

    watchEffect(() => {
        if (ready.value) {
            if (Object.keys(userInfo.value).length === 0) {
                router.replace("/");
            } else if (hasPermission.value) {
                getProductTree();
                if (state.projectId) {
                    getVersionList1();
                    getVersionList2();
                    getAnnouncementList();
                }
            }
        }
    });
    watchEffect(() => {
        if (state.projectId && state.versionId1) {
            getPeriodList();
        }
    });
    watchEffect(() => {
        if (state.projectId && state.versionId2) {
            getSaleItemData();
        }
    });
    watchEffect(() => {
        if (state.projectId && state.year && state.versionId1 && state.versionId2) {
            getYearSaleFinishData();
            getYearPaymentData();
        }
    });
    watchEffect(() => {
        if (state.projectId && state.versionId1 && state.versionId2) {
            getInvestorMainIndexData();
            getAXMainIndexData();
            getInvestorCashFlowTotal();
            getAXCashFlowTotal();
            getProjectVolumnSaleData();
            getProjectSaleData();
            getProjectIntroduction();
            getYearRepaymentData();
        }
    });
    watchEffect(() => {
        // period只影响开发节点数据的获取，后端处理period为空的情况
        if (state.projectId && state.versionId1 && state.versionId2 /*&& state.period*/) {
            getMainIndexData();
        }
    });
    watchEffect(() => {
        if (state.projectId && state.versionId2) {
            getProjectAccountDetail();
        }
    });
    watchEffect(() => {
        if (state.projectId && state.versionId1 && state.versionId2) {
            getOperateCashFlowTotal();
            getFinanceCashFlowTotal();
        }
    });

    watch(() => state.projectId, () => {
        state.versionList1 = [];
        state.versionList2 = [];
        state.periodList = [];
        state.years = [];
        state.versionId1 = null;
        state.versionId2 = null;
        state.mainIndexData = {};
        state.saleItemData = {};
        state.investorMainIndexData = {};
        state.axMainIndexData = {};
        state.announcementList = [];
        state.projectIntroduction = {};
        state.operateCashFlowTotal = {};
        state.financeCashFlowTotal = {};
        state.investorCashFlowTotal = {};
        state.axCashFlowTotal = {};
        state.projectVolumnSaleData = {};
        state.projectSaleData = {};
        state.projectAccountDetail = {};
        state.yearSaleFinishData = {};
        state.yearRepaymentData = {};
        state.yearPaymentData = {};
    });

    for (let year = 2021; year <= (new Date()).getFullYear(); year++) {
        state.years.push(year);
    }

    return {
        ...toRefs(state),
        hasPermission,
        currentProject,
        version1,
        version2
    };
}

export default {
    init() {
        if (!composition) {
            composition = useProject();
        }
        return composition;
    },
    destroy() {
        composition = null;
    }
}