import request from "@/utils/request";

function getProductList() {
  return request({
    url: "datav/product/product_map",
    method: "get"
  });
}

function getProductIntroduction(productId) {
  return request({
    url: "datav/product/product_intro",
    method: "get",
    params: { product_id: productId }
  });
}

function getProductNetWorthData(productId) {
  return request({
    url: "datav/product/net_worth",
    method: "get",
    params: { product_id: productId }
  });
}

function getProjectStat(productId) {
  return request({
    url: "datav/product/project_stat",
    method: "get",
    params: { product_id: productId }
  });
}

function getAnnouncementList(productId) {
  return request({
    url: "datav/product/announcement_list",
    method: "get",
    params: { product_id: productId }
  });
}

function getProjectList(productId) {
  return request({
    url: "datav/product/project_list",
    method: "get",
    params: { product_id: productId }
  });
}

export default {
  getProductList,
  getProductIntroduction,
  getProductNetWorthData,
  getProjectStat,
  getAnnouncementList,
  getProjectList
}