import { reactive, computed, toRefs, watchEffect } from "vue";
import { useRouter } from "vue-router";
import user from "@/compositions/user";
import generalApi from "@/apis/generalApi";

let composition = null;

function useGeneral() {
    const router = useRouter();

    const { ready, userInfo, permissions } = user.init();
    
    const state = reactive({
        axIntro: {},
        productCategory: {},
        productList: [],
        productId: null,
        productStat: {},
        announcementList: [],
        projectList: [],
        projectId: null
    });

    const hasPermission = computed(() => {
        const perm = "general:view";
        return permissions.value.includes(perm);
    });

    const productIndex = computed(() => {
        for (let i = 0; i < state.productList.length; i++) {
            if (state.productList[i].productId === state.productId) {
                return i;
            }
        }
        return null;
    });

    const project = computed(() => {
        for (let i = 0; i < state.projectList.length; i++) {
            if (state.projectList[i].projectId === state.projectId) {
                return state.projectList[i];
            }
        }
        return null;
    });

    const getAXIntro = async () => {
        try {
            const response = await generalApi.getAXIntro();
            if (response.ret === 0) {
                state.axIntro = response.data;
            }
        } catch (error) {
            console.warn(error);
        }
    };

    const getProductCategory = async () => {
        try {
            const response = await generalApi.getProductCategory();
            if (response.ret === 0) {
                state.productCategory = response.data;
            }
        } catch (error) {
            console.warn(error);
        }
    };

    const getProductList = async () => {
        try {
            const response = await generalApi.getProductList();
            if (response.ret === 0) {
                state.productList = response.data;
                if (!state.productId && state.productList.length > 0) {
                    state.productId = state.productList[0].productId;
                }
            }
        } catch (error) {
            console.warn(error);
        }
    };

    const getProductStat = async () => {
        try {
            const response = await generalApi.getProductStat();
            if (response.ret === 0) {
                state.productStat = response.data;
            }
        } catch (error) {
            console.warn(error);
        }
    };

    const getAnnouncementList = async () => {
        try {
            const response = await generalApi.getAnnouncementList();
            if (response.ret === 0) {
                state.announcementList = response.data;
            }
        } catch (error) {
            console.warn(error);
        }
    };

    const getProjectList = async () => {
        try {
            const response = await generalApi.getProjectListByProductId(state.productId);
            if (response.ret === 0) {
                state.projectList  = response.data;
                if (state.projectList.length > 0) {
                    state.projectId = state.projectList[0].projectId;
                } else {
                    state.projectId = null;
                }
            }
        } catch (error) {
            console.warn(error);
        }
    };

    watchEffect(() => {
        if (ready.value) {
            if (Object.keys(userInfo.value).length === 0) {
                router.replace("/");
            } else if (hasPermission.value) {
                getAXIntro();
                getProductCategory();
                getProductList();
                getProductStat();
                getAnnouncementList();
                if (state.productId) {
                    getProjectList();
                }
            }
        }
    });
    

    return {
        ...toRefs(state),
        hasPermission,
        productIndex,
        project
    };
}

export default {
    init() {
        if (!composition) {
            composition = useGeneral();
        }
        return composition;
    },
    destroy() {
        composition = null;
    }
}