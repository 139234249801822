import { ref } from "vue";

let composition = null;

function useScreen() {
    const style = ref({});
    const onResize = () => {
      const designWidth = 1920;
      const designHeight = 1080;
      const innerWidth = window.innerWidth;
      const innerHeight = window.innerHeight;
      const scaleX = innerWidth / designWidth;
      const scaleY = innerHeight / designHeight;
      const scale = Math.min(scaleX, scaleY);
      style.value = {
        transform: `scale(${scale})`
      };
    }
    window.addEventListener("resize", onResize);
    onResize();
    return { style };
}

export default {
    init() {
        if (!composition) {
            composition = useScreen();
        }
        return composition;
    },
    destroy() {
        composition = null;
    }
}