import { createRouter, createWebHistory } from "vue-router";
import General from "@/views/General";
import Login from "@/views/Login";

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login
  },
  {
    path: "/general",
    name: "General",
    component: General
  },
  {
    path: "/product",
    name: "Product",
    component: () => import("@/views/Product")
  },
  {
    path: "/project",
    name: "Project",
    component: () => import("@/views/Project")
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  if (to.name === "Product" && from.name === "Project") {
    if (from.params.id) {
      to.query.id = from.params.id;
    }
  }
  next();
});

export default router;
