<template>
  <div class="empty">
    <img class="icon" :src="emptyIcon" />
    <span class="desc">{{desc || "暂无数据"}}</span>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "Empty",
  props: ["desc"],
  setup() {
    const store = useStore();
    const emptyIcon = computed(() => store.getters["theme/emptyIcon"]);
    return {
      emptyIcon
    };
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/main.scss";

.empty {
  @extend %center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: var(--main-background-color);

  .icon {
    width: 22%;
    max-width: 80px;
  }

  .desc {
    margin-top: 8px;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: var(--secondary-font-color);
    line-height: 19px;
  }
}
</style>

