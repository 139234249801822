<template>
  <div class="container">
    <div class="mask" />
    <div class="no-permission">
      <div class="inner">
        <div class="info">
          <img class="icon" src="/images/not-allow.svg" />
          <span class="text">暂无权访问</span>
        </div>
        <span class="desc">您暂时无权限访问产品大屏</span>
      </div>
      <button class="btn-back" @click="onBackClick">好的，我知道了</button>
      <div class="line-left" />
      <div class="line-right" />
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";

export default {
  name: "NoPermission",
  setup() {
    const router = useRouter();

    const onBackClick = () => {
      router.back();
    };

    return {
      onBackClick
    };
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/main.scss";

.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
}

.no-permission {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 480px;
  height: 236px;
  background: var(--main-background-color);
  box-shadow: inset 0 0 16px 0 var(--main-background-color);
  z-index: 10001;
}

.line-left {
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 100%;
  background-color: var(--brand-color);
  z-index: 10001;
}

.line-right {
  position: absolute;
  top: 0;
  right: 0;
  width: 2px;
  height: 100%;
  background-color: var(--brand-color);
  z-index: 10001;
}

.inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
  background: url("/images/danger-bg.png");

  .info {
    @extend %horizontal;

    .icon {
      width: 32px;
      height: 32px;
    }

    .text {
      margin-left: 8px;
      font-size: 24px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: var(--brand-color);
      line-height: 32px;
    }
  }

  .desc {
    margin-top: 8px;
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: var(--brand-color);
    line-height: 21px;
  }
}

.btn-back {
  margin-top: 32px;
  width: 180px;
  height: 40px;
  background-color: var(--brand-color);
  border-radius: 2px;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: var(--main-font-color);
}
</style>
