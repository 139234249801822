import request from "@/utils/request";

function getProductTree() {
  return request({
    url: "datav/project/product_tree",
    method: "get"
  });
}

function getVersionList(projectId, type) {
  return request({
    url: "datav/project/version_list",
    method: "get",
    params: { project_id: projectId, type: type }
  });
}

function getPeriodList(projectId, version) {
  return request({
    url: "datav/project/get_periods",
    method: "get",
    params: { project_id: projectId, version: version }
  });
}

function getMainIndexData(projectId, version1, version2, period, date1, date2) {
  date1 = date1 === '' ? undefined : date1
  date2 = date2 === '' ? undefined : date2

  return request({
    url: "datav/project/main_index_data",
    method: "get",
    params: { project_id: projectId, version1: version1, version2: version2, period: period, date1: date1, date2: date2 }
  });
}

function getSaleItemData(project_id, version, date) {
  date = date === '' ? undefined : date
  return request({
    url: `datav/project/sale_item_data`,
    params: { project_id, version, date },
    method: 'get'
  })
}

function getInvestorMainIndexData(projectId, version1, version2, date1, date2) {
  date1 = date1 === '' ? undefined : date1
  date2 = date2 === '' ? undefined : date2
  return request({
    url: "datav/project/investor_main_index_data",
    method: "get",
    params: { project_id: projectId, version1: version1, version2: version2, date1: date1, date2: date2 }
  });
}

function getAXMainIndexData(projectId, version1, version2, date1, date2) {
  date1 = date1 === '' ? undefined : date1
  date2 = date2 === '' ? undefined : date2
  return request({
    url: "datav/project/ax_main_index_data",
    method: "get",
    params: { project_id: projectId, version1: version1, version2: version2, date1: date1, date2: date2 }
  });
}

function getAnnouncementList(projectId) {
  return request({
    url: "datav/project/announcement_list",
    method: "get",
    params: { project_id: projectId }
  });
}

function getProjectIntroduction(projectId, version1, version2) {
  return request({
    url: "datav/project/project_intro",
    method: "get",
    params: { project_id: projectId, version1: version1, version2: version2 }
  });
}

function getOperateCashFlowTotal(projectId, version1, version2, dateLimit1, dateLimit2) {
  return request({
    url: "datav/project/operate_cash_flow_total",
    method: "get",
    params: { project_id: projectId, version1: version1, version2: version2, date1: dateLimit1, date2: dateLimit2 }
  });
}

function getFinanceCashFlowTotal(projectId, version1, version2, dateLimit1, dateLimit2) {
  return request({
    url: "datav/project/finance_cash_flow_total",
    method: "get",
    params: { project_id: projectId, version1: version1, version2: version2, date1: dateLimit1, date2: dateLimit2 }
  });
}

function getInvestorCashFlowTotal(projectId, version1, version2, date1, date2) {
  date1 = date1 === '' ? undefined : date1
  date2 = date2 === '' ? undefined : date2

  return request({
    url: "datav/project/investor_cash_flow_total",
    method: "get",
    params: { project_id: projectId, version1: version1, version2: version2, date1: date1, date2: date2 }
  });
}

function getAXCashFlowTotal(projectId, version1, version2, date1, date2) {
  date1 = date1 === '' ? undefined : date1
  date2 = date2 === '' ? undefined : date2

  return request({
    url: "datav/project/ax_cash_flow_total",
    method: "get",
    params: { project_id: projectId, version1: version1, version2: version2, date1: date1, date2: date2 }
  });
}

function getProjectVolumnSaleData(projectId, version1, version2, date1, date2) {
  date1 = date1 === '' ? undefined : date1
  date2 = date2 === '' ? undefined : date2

  return request({
    url: "datav/project/project_volumn_sale_data",
    method: "get",
    params: { project_id: projectId, version1: version1, version2: version2, date1: date1, date2: date2 }
  });
}

function getProjectSaleData(projectId, version1, version2, date1, date2) {
  date1 = date1 === '' ? undefined : date1
  date2 = date2 === '' ? undefined : date2

  return request({
    url: "datav/project/project_sale_data",
    method: "get",
    params: { project_id: projectId, version1: version1, version2: version2, date1: date1, date2: date2 }
  });
}

function getProjectAccountDetail(projectId, version, dateLimit) {
  return request({
    url: "datav/project/project_account_detail",
    method: "get",
    params: { project_id: projectId, version: version, date: dateLimit }
  });
}

function getYearSaleFinishData(projectId, time, version1, version2) {
    var params = { project_id: projectId, version1: version1, version2: version2 };
  if (isNaN(time)) {
    params['deadline'] = time;
  }
  else {
    params['year'] = time;
  }

  return request({
    url: "datav/project/year_sale_finish_data",
    method: "get",
    params: params
  });
}

function getYearRepaymentData(projectId, time, version1, version2) {
  var params = { project_id: projectId, version1: version1, version2: version2 };
  if (isNaN(time)) {
    params['deadline'] = time;
  }
  else {
    params['year'] = time;
  }

  return request({
    url: "datav/project/year_repayment_data",
    method: "get",
    params: params
  });
}

function getYearPaymentData(projectId, time, version1, version2) {
  var params = { project_id: projectId, version1: version1, version2: version2 };
  if (isNaN(time)) {
    params['deadline'] = time;
  }
  else {
    params['year'] = time;
  }

  return request({
    url: "datav/project/year_payment_data",
    method: "get",
    params: params
  });
}

export default {
  getProductTree,
  getVersionList,
  getPeriodList,
  getMainIndexData,
  getSaleItemData,
  getInvestorMainIndexData,
  getAXMainIndexData,
  getAnnouncementList,
  getProjectIntroduction,
  getOperateCashFlowTotal,
  getFinanceCashFlowTotal,
  getInvestorCashFlowTotal,
  getAXCashFlowTotal,
  getProjectVolumnSaleData,
  getProjectSaleData,
  getProjectAccountDetail,
  getYearSaleFinishData,
  getYearRepaymentData,
  getYearPaymentData
}