export default {
	namespaced: true,
	state: () => ({
		userInfo: null
	}),
	mutations: {
		updateUserInfo(state, userInfo) {
			state.userInfo = userInfo;
		}
	}
}
